import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"

import { Subheading, MenuThirdHeading } from "./typography"

const SummaryTableSection = styled.section`
  width: stretch;
  border-top: 1px solid hsl(0, 0%, 20%);
  border-bottom: 1px solid hsl(0, 0%, 20%);
  padding: 20px 0;
  display: flex;
  flex-direction: row;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  @media screen and (min-width: 768px) {
    width: 50ch;
    justify-self: center;
  }

  @media screen and (min-width: 992px) {
    margin: 105px auto 0px;
  }
`

const TableCell = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  border-right: 1px solid hsl(0, 0%, 20%);
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  & > :first-child {
    margin-bottom: 0;
  }

  &:last-of-type {
    border-right: none;
  }
`

const SummaryTableComponent = ({ items }) => {
  const windowSize = useWindowSize()

  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )
  return (
    <SummaryTableSection ref={elementRef} hideOnScroll={hideOnScroll}>
      {items.map((item, index) => (
        <TableCell key={index}>
          <Subheading>{item.title}</Subheading>
          <MenuThirdHeading>{item.value}</MenuThirdHeading>
        </TableCell>
      ))}
    </SummaryTableSection>
  )
}

export default SummaryTableComponent

SummaryTableComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

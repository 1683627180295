import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useWindowSize from "./use-window-size"

import {
  MenuSubheading,
  Subheading,
  MenuThirdHeading,
  Copy,
} from "./typography"

const MenuContainer = styled.section`
  margin-left: 25px;
  margin-right: 25px;

  opacity: ${(props) => (props.hideOnScroll ? "0" : "1")};
  transform: ${(props) =>
    props.hideOnScroll ? "translateY(50px)" : "translateY(0px)"};

  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  transition-delay: 200ms;

  @media screen and (min-width: 768px) {
    margin-top: 90px;
    margin-left: 30px;
    margin-right: 30px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;

    & > * {
      max-width: 40ch;
    }
  }

  /* @media screen and (min-width: 992px) {
    margin-left: 60px;
    margin-right: 60px;
  } */
`

const MenuHeading = styled.div`
  grid-column: 1 / -1;
  margin-top: 30px;

  &,
  & > * {
    /* margin-bottom: -5px; */
  }
`

const MenuComponent = ({ menuContent }) => {
  const windowSize = useWindowSize()

  const elementRef = useRef(0)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    () =>
      setHideOnScroll(
        elementRef.current.getBoundingClientRect().top > windowSize.height
      ),
    [hideOnScroll]
  )
  return (
    <MenuContainer ref={elementRef} hideOnScroll={hideOnScroll}>
      {menuContent.map((menuContentBlock, index) =>
        menuContentBlock.__typename === "ContentfulMenuHeading" ? (
          <MenuHeading key={index}>
            {menuContentBlock.menuSectionHeading ? (
              <MenuSubheading>
                {menuContentBlock.menuSectionHeading}
              </MenuSubheading>
            ) : null}
            {menuContentBlock.menuSubsectionHeading ? (
              <Subheading>{menuContentBlock.menuSubsectionHeading}</Subheading>
            ) : null}
            {menuContentBlock.menuDescription
              ? menuContentBlock.menuDescription.menuDescription
                  .split("\n")
                  .map((paragraph, paragraphIndex) => (
                    <Copy key={paragraphIndex}>{paragraph}</Copy>
                  ))
              : null}
          </MenuHeading>
        ) : menuContentBlock.__typename === "ContentfulMenuItem" ? (
          <div key={index}>
            {menuContentBlock.displayName ? (
              <MenuThirdHeading>
                {menuContentBlock.displayName}
              </MenuThirdHeading>
            ) : null}
            {menuContentBlock.description
              ? menuContentBlock.description.description
                  .split("\n")
                  .map((paragraph, paragraphIndex) => (
                    <Copy key={paragraphIndex}>{paragraph}</Copy>
                  ))
              : null}
          </div>
        ) : null
      )}
    </MenuContainer>
  )
}

export default MenuComponent

MenuComponent.propTypes = {
  menuContent: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.string,
      menuSectionHeading: PropTypes.string,
      menuSectionDescription: PropTypes.shape({
        menuSectionDescription: PropTypes.string,
      }),
      displayName: PropTypes.string,
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
}
